import React, { useState, useEffect } from "react";
import Config from "config"

const PHASE_THREE_URL = Config.URLS.BASE + "/public";

const NoTickets = () => {
    return (
         <div className="wrapper raffle-coming-soon-wrapper">
            <div className="wrapper partner-heading-wrapper">
            <div className="text h2">You don't have any tickets</div>
            </div>
            <div className="horizontal-line"></div>
            <div className="spacer _2em"></div>
            <p className="text centred">Please go to the public sale to buy tickets<br /></p>
            <div className="wrapper button-row">
              <a href={PHASE_THREE_URL} target="_blank"
                 className="button secondary small w-inline-block">
                <div className="text">Public sale</div>
              </a>
            <div className="spacer _1em"></div>
            </div>
        </div>
    )
}

export default NoTickets
